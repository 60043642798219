body {
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 900px) {
  .__talkjs_launcher {
    margin-bottom: 40px;
  }
}
