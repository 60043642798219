.ripple-background {
  background-color: #ff6b59 !important;
  background-image: linear-gradient(155.21deg, #03DAC5 10.7%, #07b5a4 90.03%);
}

.ripple-animation .ripple {
  position: absolute;
  opacity: 0;
  -webkit-animation: ripple 1.8s infinite;
  animation: ripple 1.8s infinite;

  &:nth-child(2) {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
